import { Component } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from './store/app';
import {loadDiets} from './store/diet';
import {loadAuthState} from './store/profile';
import {loadScans} from './store/scan';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private store: Store<AppState>) {
    store.dispatch(loadAuthState());
    store.dispatch(loadDiets());
    store.dispatch(loadScans());
  }
}
