import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Profile} from '../../store/profile';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {forkJoin, from} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public readonly auth: AngularFireAuth, private afs: AngularFirestore) {
  }

  setProfile(profile: Profile) {
    return this.afs.collection('profiles').doc(profile.uid).set(profile);
  }

  create(profile: Profile) {
    return this.afs.collection('profiles').doc(profile.uid).set({
      ...profile,
      created: new Date(),
      source: window.location.pathname
    });
  }

  find(uid) {
    return this.afs.collection('profiles').doc(uid).valueChanges();
  }

  setSource(uid, source: string) {
    return this.afs.collection('profiles').doc(uid).update({source});
  }

  earlyAdopterSignup(profile: Profile) {
    return forkJoin([
      this.afs.collection('profiles').doc(profile.uid).update({earlyAdopter: true}),
      this.afs.collection('early_adopters').doc(profile.uid).set({signedUp: new Date(), profile})
    ]);
  }
}
