import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  public readonly colorArray = ['danger', 'warning', 'light', 'primary', 'success'];
  public readonly colors = this.colorArray.map((color, i) => ({
    color,
    value: i * 25
  }));
  constructor() { }

  getColor(percent: number) {
    const desc = [...this.colors].reverse();
    const active = desc.find(c => c.value <= percent);
    return active?.color;
  }
}
