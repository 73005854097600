import {createAction, props, createReducer, on, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, first, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, from, of} from 'rxjs';
import {AppState} from './app';
import {ProfileService} from '../lib/services/profile.service';
import {selectDiets} from './diet';

export interface Profile{
  isAdmin: boolean;
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
  phoneNumber: string;
  earlyAdopter?: boolean;
  role?: string;
}

export const loadAuthState = createAction('[Profile] LoadAuthState');
export const setAuth = createAction('[Profile] SetAuth', props<{auth: any}>());
export const mergeAuth = createAction('[Profile] MergeAuth', props<{auth: any}>());
export const setRole = createAction('[Profile] SetRole', props<{role: string}>());
export const createProfile = createAction('[Profile] Create', props<{auth: any}>());
export const loadProfile = createAction('[Profile] LoadProfile');
export const setNew = createAction('[Profile] SetNew');
export const earlyAdopter = createAction('[Profile] EarlyAdopter');
export const setProfile = createAction('[Profile] SetProfile', props<Profile>());
export const setAdminStatus = createAction('[Profile] SetAdminStatus', props<any>());
export const resetProfile = createAction('[Profile] Reset');

export const initialState = null;

const reducer = createReducer(
  initialState,
  on(setAuth, (state, {auth}) => ({...auth})),
  on(mergeAuth, (state, {auth}) => ({...state, ...auth})),
  on(setRole, (state, {role}) => ({...state, role})),
  on(setNew, (state) => ({...state, newSignup: true})),
  on(setProfile, (state, profile: Profile) => ({...profile})),
  on(setAdminStatus, (state, payload: any) => ({...state, isAdmin: !! payload.isAdmin})),
  on(resetProfile, (state) => null))
;

export const profileReducer = (state, action) => reducer(state, action);

// todo create effect that loads the user's profile after setProfile from auth result

@Injectable()
export class ProfileEffects {
  loadAuthState$ = createEffect(() => this.actions$.pipe(
      ofType(loadAuthState),
      switchMap(() => this.profileService.auth.authState
        .pipe(
          map(auth => {
            if (auth) {
              const {uid, displayName, photoURL, email, phoneNumber} = auth;
              return ({type: '[Profile] SetAuth', auth: {uid, displayName, photoURL, email, phoneNumber}});
            } else {
              return ({type: '[Profile] ResetProfile'});
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadAuthProfile$ = createEffect(() => this.actions$.pipe(
      ofType(setAuth),
      switchMap((action) => this.profileService.find(action.auth.uid)
        .pipe(
          take(1),
          map((profile) => {
            if (profile) {
              return ({type: '[Profile] MergeAuth', auth: profile});
            } else {
              return ({type: '[Profile] Create', auth: action.auth});
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createProfile$ = createEffect(() => this.actions$.pipe(
      ofType(createProfile),
      switchMap((action) => from(this.profileService.create(action.auth))
        .pipe(
          first(),
          tap(() => sessionStorage.setItem('newSignup', 'true')),
          map(() => ({type: '[Profile] SetNew'})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadRole$ = createEffect(() => this.actions$.pipe(
      ofType(setAuth),
      switchMap(() => this.profileService.auth.authState
        .pipe(
          switchMap(auth => from(auth.getIdTokenResult())),
          map(token => {
            const role = token?.claims?.admin ? 'admin' : 'user';
            return ({type: '[Profile] SetRole', role});
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  earlyAdopter$ = createEffect(() => this.actions$.pipe(
      ofType(earlyAdopter),
      switchMap(() => this.store.select('profile')
        .pipe(
          first(p => !! p),
          tap((profile: Profile) => this.profileService.earlyAdopterSignup(profile)),
          map(profile => ({type: '[Profile] MergeAuth', auth: {earlyAdopter: true}}),
          catchError(() => EMPTY)
        )
      )
    )
  ));

  constructor(
    private actions$: Actions, // this is an RxJS stream of all actions
    private profileService: ProfileService, // we will need this service for API calls
    private store: Store<AppState>
  ) {}
}


