import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {v4 as uuid} from 'uuid';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app';
import {Scan, ScanStatus} from '../../store/scan';
import {from, Observable, of} from 'rxjs';
import {take, takeWhile} from 'rxjs/operators';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {
  public scanQuality = 100;
  public readonly text = this.fns.httpsCallable('api-scanText');
  public readonly tag = this.fns.httpsCallable('api-tagScan');
  private userId: string;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private store: Store<AppState>,
    private fns: AngularFireFunctions
  ) {
    // todo unsubscribe
    store.select('profile').subscribe(profile => this.userId = profile ? profile.uid : null);
  }

  scan() {
    return Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: this.scanQuality
    });
  }

  async process(photo: any): Promise<Scan> {
    if (! this.userId) {
      throw new Error('You do not have access');
    }

    const id = uuid();
    const filePath = `scans/${this.userId}/${id}.${photo.format}`;

// create db doc
    const doc = {
      uid: id,
      filePath,
      status: ScanStatus.new,
      created: new Date(),
      owner: this.userId
    };
    const dbRef = await this.firestore.collection('scans').doc(id).set(doc);

    // upload the file
    const metadata = {
      contentType: 'image/jpeg',
    };

    const storageRef = this.storage.ref(filePath);
    const task = await storageRef.putString(photo.base64String, 'base64', metadata);
    const url = await storageRef.getDownloadURL().toPromise();

    await this.firestore.collection('scans').doc(id).update({url});

    return doc;
  }

  loadLink(link: string) {
    const id = uuid();
    const doc = {
      uid: id,
      link,
      status: ScanStatus.new,
      created: new Date(),
      owner: this.userId
    };
    return this.firestore.collection('scans').doc(id).set(doc).then(ref => (doc));
  }

  find(id) {
    return this.firestore.collection('scans').doc(id).valueChanges({idField: 'id'});
  }

  myScans(limit = 5): Observable<Scan[]> {
    return this.firestore.collection<Scan>('scans', ref => ref.where('owner','==', this.userId)
        .orderBy('created', 'desc')
        .limit(limit)).valueChanges();
  }

  userScans(userId, limit = 5): Observable<Scan[]> {
    return this.firestore.collection<Scan>('scans', ref => ref.where('owner','==', userId)
        .orderBy('created', 'desc')
        .limit(limit)).valueChanges();
  }

  // tag(filename, terms: any) {
  //   // todo remove backwards compatibility
  //   // change ['word'] to ['term']
  //   const normalizedTerms = terms.map(t => {
  //     let term;
  //     if (typeof t === 'string') {
  //       term = t;
  //     } else if (t.word) {
  //       term = t.word;
  //     };
  //     return term;
  //   });
  //   const fn = this.fns.httpsCallable('tagScan');
  //   console.log({filename});
  //   return fn(filename);
  // }
}
