import {createAction, props, createReducer, on, Action, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ScannerService} from '../lib/services/scanner.service';
import {catchError, first, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {AppState} from './app';
import {selectActiveDiet, selectDietIngredients, setDiet} from './diet';
import {mergeAuth} from './profile';
import {ColorService} from '../lib/services/color.service';

export enum ScanStatus {
  new = 'new',
  uploading = 'uploading',
  processing = 'processing',
  ready = 'ready',
  loading = 'loading',
}

export interface ScanStats {
  dietId: string;
  matches: number;
  score: number;
  color: string;
  details?: any;
}

export interface Scan{
  created: Date;
  filePath: string;
  finished?: Date;
  owner: string; // firebase auth UID
  started?: Date;
  status: ScanStatus;
  uid?: string;
  url?: string;
  tags?: any[];
  stats?: ScanStats;
}

export const loadScans = createAction('[Scan] LoadScans');
export const setScan = createAction('[Scan] SetScan', props<{scan: Scan}>());
export const mergeScan = createAction('[Scan] MergeScan', props<{scan: any}>());
export const setScans = createAction('[Scan] SetScans', props<any>());
export const loadScan = createAction('[Scan] LoadScan', props<{scanId: string}>());
export const tagScan = createAction('[Scan] TagScan', props<any>());
export const tagScanError = createAction('[Scan] TagScanError', props<any>());
export const setScanStatus = createAction('[Scan] SetScanStatus', props<{status: ScanStatus}>());
export const setScanTags = createAction('[Scan] SetScanTags', props<{tags: any[]; stats: ScanStats}>());
export const setScanStats = createAction('[Scan] SetScanStats', props<{stats}>());
export const resetScan = createAction('[Scan] ResetScan');

export interface ScanState {
  active: Scan;
  list: Scan[];
}

export const initialState = {
  active: null,
  list: []
};

const reducer = createReducer(
  initialState,
  on(setScan, (state, payload) => ({...state, active: {...payload.scan}})),
  on(mergeScan, (state, {scan}) => ({...state, active: {...state.active, ...scan}})),
  on(setScanStatus, (state, {status}) => ({...state, active: {...state.active, status}})),
  on(setScanTags, (state, payload) => {
    const active = {...state.active};
    active.tags = [...payload.tags];
    active.stats = {...payload.stats};
    active.status = ScanStatus.ready;
    return ({...state, active});
  }),
  on(setScans, (state, {list}) => ({...state, list: list ? [...list] : []})),
  on(resetScan, (state) => ({...state, active: null}))
);

export const scanReducer = (state, action) => reducer(state, action);

export const selectScans = (state: AppState) => [...state.scan.list];
export const selectActiveScan = (state: AppState) => state.scan.active ? {...state.scan.active} : null;
export const selectTags = (state: AppState) => state.scan.active?.tags ? [...state.scan.active.tags] : null;
export const selectScanStats = (state: AppState) => state.scan.active?.stats ? {...state.scan.active.stats} : null;

@Injectable()
export class ScanEffects {
  // @ts-ignore
  loadScan$ = createEffect(() => this.actions$.pipe(
      ofType(loadScan),
      mergeMap((action) => this.scannerService.find(action.scanId)
        .pipe(
          tap(() => this.store.dispatch(mergeScan({scan: {uid: action.scanId, status: ScanStatus.loading}}))),
          map(scan => ({type: '[Scan] SetScan', scan})),
          catchError(() => EMPTY)
        )
      )
    )
  );
  // @ts-ignore
  myScans$ = createEffect(() => this.actions$.pipe(
      ofType(loadScans),
      switchMap((action) => this.store.select('profile')
        .pipe(
          first(p => !! p),
          switchMap(profile => this.scannerService.userScans(profile?.uid)),
          map(scans => {
            if (scans) {
              return {type: '[Scan] SetScans', list: [...scans]};
            } else {
              return {type: '[Scan] ResetScans'};
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );
  tagScan$ = createEffect(() => this.actions$.pipe(
      ofType(setScan),
      switchMap((action) => this.store.select(selectDietIngredients)
        .pipe(
          first(),
          mergeMap((ingredients) => {
            const req: any = {filename: action.scan.uid};
            if (ingredients?.length > 0) {
              req.terms = ingredients.map(f => f.key);
            }
            return this.scannerService.tag(req);
          }),
          // todo we already have this data, cant figure out how to access it right now
          withLatestFrom(this.store.select(selectActiveDiet), this.store.select(selectDietIngredients)),
          map(([tagList, diet, ingredients]) => {
            const payload: any = {};
            payload.tags = ! tagList ? [] : tagList.map(t => {
              const i = ingredients.find(ing => t.term.indexOf(ing.key) > -1);
              const color = i ? this.colorService.getColor(i.percent) : null;
              return i ? {...t, ...i, color} : {...t, color};
            }).filter(t => t.color !== null);
            const score = this.calculateScore(payload?.tags);
            const details = this.calculateDetails(payload?.tags);
            payload.stats = {
              dietId: diet.uid,
              matches: payload.tags.length,
              score,
              details
            };
            return ({type: '[Scan] SetScanTags', ...payload});
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );
  constructor(
    private actions$: Actions, // this is an RxJS stream of all actions
    private colorService: ColorService,
    private scannerService: ScannerService, // we will need this service for API calls
    private store: Store<AppState>
  ) {}

  calculateScore(tags) {
    if (tags.length === 0) {
      return null;
    }
    const withPercent = [...tags.map(t => ({...t, percent: (t.value + 2) * 25}))];
    return withPercent.reduce((sum, tag) => sum + tag.percent, 0) / withPercent.length;
  }

  calculateDetails(tags) {
    if (tags.length === 0) {
      return null;
    }
    const types = ['danger', 'warning', 'light', 'primary', 'success'];
    const details = types.reduce((obj, type) => ({...obj, [type]: {count: 0, percent: 0}}), {});

    tags.forEach(t => {
      if (t) {
        const key = types[t.value + 2];
        if (key) {
          details[key].count++;
          details[key].percent = details[key].count / tags.length;
        }
      }
    });
    return details;
  }

}
