import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';

const redirectToSignIn = () => redirectUnauthorizedTo(['/sign-in']);
const adminOnly = () => hasCustomClaim('admin');

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectToSignIn },
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'docs',
    loadChildren: () => import('./pages/docs/docs.module').then( m => m.DocsPageModule)
  },
  {
    path: 'diet',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectToSignIn },
    loadChildren: () => import('./pages/diet/diet.module').then( m => m.DietPageModule)
  },
  {
    path: 'profile',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectToSignIn },
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'scan',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectToSignIn },
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'learn',
    loadChildren: () => import('./pages/learn/learn.module').then( m => m.LearnPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'manager',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly },
    loadChildren: () => import('./pages/manager/manager.module').then( m => m.ManagerPageModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('./pages/qr/qr.module').then( m => m.QrPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
