import { Injectable } from '@angular/core';
import {BehaviorSubject, from, of, Subject} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Diet, setDiet, setDiets, setIngredients} from '../../store/diet';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app';
import {diets} from '../data';

@Injectable({
  providedIn: 'root'
})
export class DietService {
  constructor(private afs: AngularFirestore, private store: Store<AppState>) { }

  list() {
    return this.afs.collection<Diet>('diets', ref => ref
      .orderBy('title', 'desc')
    ).valueChanges({idField: 'uid'});
  }

  find(id) {
    return this.afs.collection<Diet>('diets').doc(id).valueChanges();
  }

  getIngredients(dietId) {
    return this.afs.collection<Diet>('diets').doc(dietId).collection('ingredients').valueChanges({idField: 'uid'});
  }

  save(data) {
    return this.afs.collection('diets').doc(data.uid).update(data);
  }

  saveLink(dietId, data) {
    return this.afs.collection<Diet>('diets').doc(dietId).valueChanges().pipe(
      first(d => !! d),
      switchMap(diet => {
        const links = diet?.links ? [...diet.links] : [];
        const existing = links.findIndex(l => l.link === data.link);
        if (existing > -1) {
          links[existing] = {...data};
        } else {
          links.push({...data});
        }
        return from(this.afs.collection<Diet>('diets').doc(dietId).update({links}));
    }));
  }

  removeLink(dietId, linkId) {
    return this.afs.collection('diets').doc(dietId).collection('links').doc(linkId).delete();
  }

  saveIngredient(dietId, ingredient, value, description = null) {
    const collection = this.afs.collection('diets').doc(dietId).collection('ingredients');
    const key = this.getKey(ingredient);
    collection.doc(key).set({key, value, dietId, description});
  }

  removeIngredient(dietId, uid) {
    return this.afs.collection('diets').doc(dietId).collection('ingredients').doc(uid).delete();
  }

  // todo move ignore to its own service
  ignore(word) {
    const key = this.getKey(word);
    this.afs.collection('ignore').doc(key).set({key});
  }

  unignore(word) {
    const key = this.getKey(word);
    this.afs.collection('ignore').doc(key).delete();
  }

  getIgnoredWords() {
    return this.afs.collection('ignore').valueChanges().pipe(map(wordList => wordList.map((w: any) => w.key)));
  }

  getKey(key) {
    return key.toLowerCase().trim().replace(/[\W_]+/g, '');
  }

}
