import {createAction, props, createReducer, on, Store} from '@ngrx/store';
import {AppState} from './app';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DietService} from '../lib/services/diet.service';
import {catchError, first, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, from, of} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {v4 as uid} from 'uuid';

export interface Diet{
  title: string;
  shortDescription?: string;
  description: string;
  ingredients: DietIngredient[];
  links?: DietLink[];
  groups?: DietGroup[];
  tags?: DietTag[];
  type: string;
  uid?: string;
  enabled: boolean;
  visible: boolean;
  deleted?: boolean;
  dirty?: boolean;
}

export interface DietIngredient {
  uid?: string;
  key: string;
  value: number;
  percent: number;
  description: string;
}

export interface DietLink {
  uid?: string;
  title: string;
  excerpt: string;
  link: string;
}

export interface DietTag {
  tag: string;
  posts: number;
}

export interface DietGroup {
  uid?: string;
  title: string;
  members: number;
  link: string;
}

export interface DietState {
  list: Diet[];
  active: Diet;
}

export const loadDiets = createAction('[Diet] LoadDiets');
export const loadDiet = createAction('[Diet] LoadDiet', props<{dietId: string}>());
export const setDiets = createAction('[Diet] SetDiets', props<any>());
export const createDiet = createAction('[Diet] CreateDiet', props<{diet: Diet;}>());
export const setDiet = createAction('[Diet] SetDiet', props<{diet: Diet; key?: string}>());
export const setIngredients = createAction('[Diet] SetIngredients', props<any>());
export const addLink = createAction('[Diet] AddLink');
export const setLink = createAction('[Diet] SetLink', props<{link: DietLink}>());
export const setGroups = createAction('[Diet] SetGroups', props<{groups: DietGroup[]}>());
export const removeLink = createAction('[Diet] RemoveLink', props<{link: DietLink}>());
export const setTags = createAction('[Diet] SetTags', props<{tags: DietTag[]}>());

export const saveDiet = createAction('[Diet] SaveDiet');
export const deleteDiet = createAction('[Diet] DeleteDiet');
export const resetDiet = createAction('[Diet] ResetDiets');

export const initialState = {
  list: [],
  active: null
};

const reducer = createReducer(
  initialState,
  on(setDiets, (state, payload: {diets: Diet[]}) => {
    const list = payload.diets ? [...payload.diets] : [];
    return {...state, list};
    }),
  on(setDiet, (state, payload: any) => {
    const diet = payload.key ? state.list.find(d => d.key === payload.key) : payload.diet;
    const active = {...state.active, ...diet};
    return {...state, active};
  }),
  on(setIngredients, (state, payload: {ingredients: DietIngredient[]}) => {
    const ingredients = payload.ingredients ? [...payload.ingredients] : [];
    return {...state, active: {...state.active, ingredients}};
  }),
  on(setGroups, (state, payload: {groups: DietGroup[]}) => {
    const groups = payload.groups ? [...payload.groups] : [];
    return {...state, active: {...state.active, groups}, dirty: true};
  }),
  on(setTags, (state, payload: {tags: DietTag[]}) => {
    const tags = payload.tags ? [...payload.tags] : [];
    return {...state, active: {...state.active, tags}, dirty: true};
  }),
  on(addLink, (state) => {
    const links: DietLink[] = state.active.links ? [...state.active.links] : [];
    links.push({excerpt: '', link: '', title: '', uid: uid()});
    const active = {...state.active, links};
    return {...state, active};
  }),
  on(setLink, (state, payload: {link: DietLink}) => {
    const links = state.active.links ? [...state.active.links] : [];
    const index = links.findIndex(l => l.uid === payload.link.uid);
    if (index === -1) {
      links.push({...payload.link});
    } else {
      links[index] = {...payload.link};
    }
    const active = {...state.active, links, dirty: true};
    return {...state, active};
  }),
  on(removeLink, (state, payload: {link: DietLink}) => {
    if (state.active.links) {
      const links = [...state.active.links];
      const index = links.findIndex(l => l.uid === payload.link.uid);
      if (index > -1) {
        links.splice(index, 1);
      }
      const active = {...state.active, links, dirty: true};
      return {...state, active};
    }
    return state;
  }),
  on(resetDiet, (state) => initialState))
;

export const selectDiets = (state: AppState) => [...state.diet.list];
export const selectActiveDiet = (state: AppState) => state.diet.active ? {...state.diet.active} : null;
export const selectDietIngredients = (state: AppState) => state.diet.active?.ingredients ? [...state.diet.active.ingredients] : null;
export const selectDietLinks = (state: AppState) => state.diet.active?.links ? [...state.diet.active.links] : null;

export const dietReducer = (state, action) => reducer(state, action);

@Injectable()
export class DietEffects {
  loadDiets$ = createEffect(() => this.actions$.pipe(
      ofType(loadDiets),
      switchMap(() => this.dietService.list()
        .pipe(
          first(d => !! d),
          map(diets => ({type: '[Diet] SetDiets', diets})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadDiet$ = createEffect(() => this.actions$.pipe(
      ofType(loadDiet),
      withLatestFrom(this.store.select(selectDiets)),
      switchMap(([action, diets]) => {
        let diet: Diet;
        if (action.dietId === 'create') {
          diet = {
            shortDescription: '',
            description: '',
            enabled: false,
            ingredients: [],
            type: '',
            visible: false,
            uid: 'create',
            title: null
          };
        } else {
          diet = diets?.find(d => d.uid === action.dietId);
        };
          const ref = diet ? of(diet) : this.dietService.find(action.dietId);
          return ref.pipe(
              first(d => !! d),
              map(res => ({type: '[Diet] SetDiet', diet: res})),
              catchError(() => EMPTY)
            );
        }
      )
    )
  );

  loadIngredients$ = createEffect(() => this.actions$.pipe(
      ofType(setDiet),
      withLatestFrom(this.store.select(selectActiveDiet)),
      switchMap(([action, diet]) => this.dietService.getIngredients(diet.uid)
        .pipe(
          first(d => !! d),
          map(ingredients => ({type: '[Diet] SetIngredients', ingredients})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  saveDiet$ = createEffect(() => this.actions$.pipe(
      ofType(saveDiet),
      withLatestFrom(this.store.select(selectActiveDiet)),
      switchMap(([action, diet]) => {
        delete diet.dirty;
        return from(this.afs.collection<Diet>('diets')
          .doc(diet.uid)
          .set(diet, {merge: true})
          .then(res => ({type: '[Diet] SetDiet', diet: {...diet, dirty: false}})));
      }
    ))
  );

  deleteDiet$ = createEffect(() => this.actions$.pipe(
      ofType(deleteDiet),
      withLatestFrom(this.store.select(selectActiveDiet)),
      switchMap(([action, diet]) => from(this.afs.collection<Diet>('diets')
        .doc(diet.uid)
        .update({deleted: true})
        .then(res => ({type: '[Diet] SetDiet', diet: {...diet, deleted: true}})))
    ))
  );

  constructor(
    private actions$: Actions, // this is an RxJS stream of all actions
    private afs: AngularFirestore,
    private dietService: DietService, // we will need this service for API calls
    private store: Store<AppState>
  ) {}
}

