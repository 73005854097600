import {createAction, props, createReducer, on, Store, createSelector} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, first, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {AppState} from '../app';
import {TextService} from '../../lib/services/text.service';

export interface Text {
  uid: string;
  classifiedAt?: Date;
  classifiedBy?: string; // uid of classifier
  created?: Date;
  length?: number;
  lookup?: number;
  matches?: number;
  plural?: string;
  singular?: string;
  term: string;
  type: string;
}

export const setText = createAction('[Text] SetText', props<{text: Text[]}>());
export const setStatus = createAction('[Text] SetStatus', props<{status: string}>());
export const loadText = createAction('[Text] Load');
export const saveWord = createAction('[Text] SetWord', props<{word: Text}>());
export const setWord = createAction('[Text] SetWord', props<{word: Text}>());
export const persistText = createAction('[Text] Persist');
export const resetText = createAction('[Text] Reset');

export interface TextState {
  text: Text[];
  status: string;
}

export const initialState: TextState = {
  text: [],
  status: 'empty'
};

const reducer = createReducer(
  initialState,
  on(setText, (state, {text}) => ({...state, text: [...text]})),
  on(setWord, (state, {word}) => {
    const text = [...state.text];
    const idx = text.findIndex(t => t.uid === word.uid);
    text[idx] = {...word};
    return {...state, text};
  }),
  on(setStatus, (state, {status}) => ({...state, status})),
  on(resetText, (state) => ({...initialState})))
;

export const textReducer = (state, action) => reducer(state, action);


export const selectText = (state: AppState) => [...state.text.text];
export const selectTextStatus = (state: AppState) => [...state.text.status];
export const selectTextByType = createSelector(
  selectText,
  (text, {type}) => text.filter(t => t.type === type)
);

@Injectable()
export class TextEffects {
  // todo we need to figure out how to handle this once there are larger numbers of words
  loadText$ = createEffect(() => this.actions$.pipe(
      ofType(loadText),
      switchMap(() => this.textService.load()
        .pipe(
          first(d => !! d),
          map(text => ({type: '[Text] SetText', text})),
          catchError(() => EMPTY)
        )
      )
    )
  );
  // todo fix saveWord$ effect so it only runs once
  // saveWord$ = createEffect(() => this.actions$.pipe(
  //     ofType(saveWord),
  //     withLatestFrom(this.store.select('profile')),
  //     switchMap(([action, profile]) => this.textService.setWord(action.word)
  //       .pipe(
  //         first(),
  //         map(word => ({type: '[Text] SetWord', word})),
  //         catchError(() => EMPTY)
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions, // this is an RxJS stream of all actions
    private textService: TextService, // we will need this service for API calls
    private store: Store<AppState>
  ) {}
}

