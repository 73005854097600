import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app';
import {Text} from '../../store/admin/text';
import {from} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private isAdmin = false;
  constructor(private afs: AngularFirestore, private store: Store<AppState>) {
    this.store.select('profile').subscribe(u => this.isAdmin = u?.isAdmin);
  }

  load(startAfter?: string) {
    return this.afs.collection<Text>('text', ref => {
        let query = ref
          .orderBy('created', 'desc')
          .limit(1000);

        if (startAfter) {
          query = ref.startAfter(startAfter);
        }
        return query;
      }
    ).valueChanges({idField: 'uid'});
  }

  setWord(word: Text) {
    return from(this.afs.collection('text').doc(word.uid).update(word).then(() => word));
  }
}
